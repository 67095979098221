import * as React from "react";
import { SimpleForm, Edit, TextInput, DateField, BooleanInput } from 'react-admin';

// const ClaimTitle = () => {
//     const record = useRecordContext()
//     console.log(record)
//     return <span>Member: {record.lineName ? `${record.lineName}` : ''}</span>;
// };
// title={<ClaimTitle />}
export const ClaimEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic" >
        <SimpleForm>
            <TextInput disabled source="member.userId" style={{ display: 'inline-block', float: 'left', marginLeft: '20px' }} />
            <TextInput disabled label="Line" source="member.lineName" />
            <TextInput disabled source="member.email" style={{ display: 'inline-block', float: 'left', marginLeft: '20px' }} />
            <TextInput disabled source="member.class" />
            <TextInput disabled source="member.phoneNumber" style={{ display: 'inline-block', float: 'left', marginLeft: '20px' }} />
            <TextInput disabled multiline source="member.address" />
            <BooleanInput source="fullfill" />
            <DateField source="created" showTime style={{ marginLeft: '20px' }} />
            <DateField source="updated" showTime style={{ marginLeft: '20px' }} />
        </SimpleForm>
    </Edit>
);