import Button from '@mui/material/Button';
import axios from 'axios'
import Swal from 'sweetalert2'


const AddMultipleBtn = (props) => {
    const credential = JSON.parse(localStorage.getItem('credential'));
    async function importFile(inputValue = '') {
        const { value: list } = await Swal.fire({
            title: 'Import Lookup',
            input: 'textarea',
            inputPlaceholder:'userId,Grandmaster,ชื่อ,นามสกุล\nuserId2,Member,ชื่อ,นามสกุล',
            inputValue: inputValue,
            showCancelButton: true
        });
        if (list) {
            try {
                Swal.fire({
                    title: 'Adding...', didOpen: () => {
                        Swal.showLoading()
                    }
                })
                const response = await axios.post(process.env.REACT_APP_API + "/backendLookup/multipleLookup", {
                    data: list
                },
                    {
                        headers: {
                            Authorization: 'Bearer ' + credential?.token
                        }
                    });
                console.log('response', response)
                await Swal.fire(
                    'Success',
                    '',
                    'success'
                )
            } catch (e) {
                console.log('EEEE', e.response)
                await Swal.fire({ title: 'Error', icon: 'error', text: e.response.data });
                console.log('E', e.response.data.error)
                importFile(list)
            }

        }

    }
    return <Button variant="contained" label="Add Lookup" color="secondary" style={props.style} onClick={importFile}>Add Lookup</Button>
}

export default AddMultipleBtn;