import axios from 'axios';
import decodeJwt from 'jwt-decode';
// eslint-disable-next-line
export default {
    login: async ({ username, password }) => {
        try {
            const credential = await axios.post(process.env.REACT_APP_API + '/users/login', {
                email: username,
                password: password
            })
            localStorage.setItem('credential', JSON.stringify(credential.data.user));
            localStorage.setItem('permissions', decodeJwt(credential.data.user.token).class)
            localStorage.setItem('identity',credential.data.user.email)
            return Promise.resolve();
        } catch (e) {
            return Promise.reject({ message: e.response?.data.errors.message || e.message })
        }
    },
    logout: () => {
        localStorage.removeItem('credential');
        localStorage.removeItem('permissions');
        localStorage.removeItem('identity')
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (error) => {
        if (!error) {
            return Promise.resolve();
        }
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('credential');
            localStorage.removeItem('permissions');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        const credential = JSON.parse(localStorage.getItem('credential'));
        if (!credential) {
            return Promise.reject();
        }
        await axios.get(process.env.REACT_APP_API + '/users/profile', {
            headers: {
                'Authorization': `Bearer ${credential.token}`
            }
        })
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getIdentity:() =>{
        return localStorage.getItem('identity')
    },
    getPermissions: () => {
        let role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};