// import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useRecordContext } from 'react-admin';

const AddButton = (props) => {
    const record = useRecordContext()
    const credential = JSON.parse(localStorage.getItem('credential'));
    async function addCoin() {
        const { value: newCoin } = await Swal.fire({ title: 'How many Coins?', input: 'number' });
        if (newCoin) {
            const { value: reason } = await Swal.fire({ title: 'Reason?', input: 'text' });
            await axios.post(process.env.REACT_APP_API + "/members/addCoin", {
                memberId: record.id,
                newCoin: newCoin,
                reason: reason || ''
            }, { headers: { Authorization: 'Bearer ' + credential?.token } });
            await Swal.fire(
                newCoin + ' Coins added',
                '',
                'success'
            )
            window.location.reload()
        }

    }
    return <Button variant="contained" label="Add Coins" color="primary" style={props.style} onClick={addCoin}>Coins+</Button>
}

export default AddButton;