import * as React from "react";
import { Filter, TextInput, List, Datagrid, TextField, DateField, SelectField } from 'react-admin';


const LookupsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);
export const LookupsList = (props) => {
    return (
        <List {...props} sort={{ field: 'created', order: 'DESC' }} exporter={false} filters={<LookupsFilter />}>
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <DateField source="created" showTime />
                <TextField source="userId" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="coins" />

                <SelectField source="class" choices={[
                    { id: 'Member', name: 'Member' },
                    { id: 'Professional', name: 'Professional' },
                    { id: 'Master', name: 'Master' },
                    { id: 'Grandmaster', name: 'Grandmaster' },
                ]} />
                <DateField source="updated" showTime />
            </Datagrid>
        </List>
    );
};