import * as React from "react";
import { Create, TextInput, NumberInput, BooleanInput, ImageInput, ImageField, SimpleForm, SelectInput, required } from 'react-admin';
import { useWatch } from 'react-hook-form'

export const ActivityCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" style={{ float: "left", marginLeft: '20px', width: "30%" }} validate={required()} />
            <SelectInput source="type" validate={required()} choices={[
                { id: 'now', name: 'now' },
                { id: 'announce', name: 'announce' }
            ]} style={{ marginLeft: '20px', width: "30%" }} />
            <TextInput source="tagline" style={{ marginLeft: '20px', width: "30%" }} />
            <TextInput multiline source="details" style={{ marginLeft: '20px' }} validate={required()} />
            <ImageInput source="thumbnail" accept="image/*" validate={required()}>
                <PreviewImage source="src" />
            </ImageInput>
            <ImageInput source="header" accept="image/*" validate={required()}>
                <PreviewImage source="src" />
            </ImageInput>
            <BooleanInput source="hide" style={{ float: "left", paddingLeft: '30px' }} initialValue={false} />
            <BooleanInput source="raffle" style={{ float: "left", paddingLeft: '30px' }} initialValue={false} />
            <CanBeDisableBooleanInput source="open" style={{ paddingLeft: '30px' }} />
            {/* <CanBeDisableNumberInput source="price" style={{ marginLeft: '20px', width: "30%" }} /> */}
            <NumberInput source="price" defaultValue={0} style={{ marginLeft: '20px', width: "30%" }} />
        </SimpleForm>
    </Create>
);

const PreviewImage = ({ record, source }) => {
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
    return <ImageField record={record} source={source} />
}


const CanBeDisableBooleanInput = props => {
    const values = useWatch({ name: 'raffle', defaultValue: false, exact: true })
    return <BooleanInput {...props} disabled={!values} />
}

// const CanBeDisableNumberInput = props => {
//     const values = useWatch({ name: 'raffle', defaultValue: false, exact: true })
//     return <NumberInput {...props} disabled={!values} />
// }