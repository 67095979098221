import * as React from "react";
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

// const Actions = (props) => (
//     <TopToolbar>
//         <ExportButton maxResults={99999} />
//     </TopToolbar>
// );

export const ClaimList = (props) => {
    return (
        <List {...props} exporter={false} bulkActionButtons={false} sort={{ field: 'created', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <DateField showTime source="created" />
                <TextField label="Product" source="product.name" />
                <TextField label="User" source="member.userId" />
                <TextField label="Email" source="member.email" />
                <TextField label='Class' source="member.class" />
                <TextField label="Price" source="product.price" />
                <BooleanField source="fullfill"></BooleanField>
            </Datagrid>
        </List>
    );
};