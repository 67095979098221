import * as React from "react";
import { List, Datagrid, TextField, DateField } from 'react-admin';

export const TransactionList = (props) => {
    return (
        <List {...props} sort={{ field: 'created', order: 'DESC' }}>
            <Datagrid bulkActionButtons={false}>
                <DateField showTime source="created" />
                <TextField label="Product" source="product" />
                <TextField label="User" source="member" />
                {/* <TextField label='Class' source="member.class" /> */}
                <TextField source="type" />
                <TextField label="Changes" source="changes" />
                <TextField label="Price" source="product.price" />
                <TextField label="Balance" source="balanceAfter" />
                {/* <TextField source="reason" /> */}
                <TextField label="Operator" source="user.email" />
            </Datagrid>
        </List>
    );
};