import * as React from "react";
import { List, Datagrid, TextField, BooleanField, NumberField } from 'react-admin';
export const ActivityList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} sort={{ field: 'created', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <TextField source="tagline" />
                <TextField source="details" cellClassName="truncator"/>
                <TextField source="type" />
                <BooleanField source="open" />
                <BooleanField source="hide" />
                <NumberField source="participants" />
                <NumberField source="price" />
            </Datagrid>
        </List>
    );
};