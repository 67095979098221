// import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import axios from 'axios'
import Swal from 'sweetalert2'

const SendMessageButton = (props) => {
    const credential = JSON.parse(localStorage.getItem('credential'));
    async function addCoin() {
        const { value: text } = await Swal.fire({ title: 'What your message?', input: 'text' });
        if (text) {
            try {
                await axios.post(process.env.REACT_APP_API + "/claims/sendMessage", {
                    memberIds: props.selectedIds,
                    message: text,
                }, { headers: { Authorization: 'Bearer ' + credential?.token } });
                await Swal.fire(
                    props.selectedIds.length + ' Members Send',
                    'success'
                )
            } catch (e) { 
                console.log(e)
                await Swal.fire(
                    'Error',
                    e.response.data,
                    'error'
                )
            }

        }

    }
    return <Button variant="contained" label="Add Coins" color="secondary" style={props.style} onClick={addCoin}>SendMsg</Button>
}

export default SendMessageButton;