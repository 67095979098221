import * as React from "react";
import {
    TabbedForm, FormTab, Edit, Datagrid, ReferenceManyField,
    TextInput, DateField, SelectInput, TextField, BooleanField, EditButton, BooleanInput
} from 'react-admin';
import AddButton from './addButton'

// const MemberTitle = () => {
//     const record = useRecordContext()
//     console.log(record)
//     return <span>{record.class} : {record.userId} </span>;
// };
export const MemberEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic">
        <TabbedForm>
            <FormTab label="Profile" style={{ flexDirection: 'unset', flexWrap: 'wrap' }}>
                <TextInput disabled source="userId" className="inlineBlock" />
                <TextInput disabled label="Line" source="lineName" className="inlineBlock" />
                <TextInput label="lineId" source="lineId" className="inlineBlock" />
                <TextInput source="email" className="inlineBlock" />
                <TextInput source="firstName" className="inlineBlock" />
                <TextInput source="lastName" className="inlineBlock" />
                <SelectInput source="class" className="inlineBlock" choices={[
                    { id: 'Member', name: 'Member' },
                    { id: 'Professional', name: 'Professional' },
                    { id: 'Master', name: 'Master' },
                    { id: 'Grandmaster', name: 'Grandmaster' },
                ]} />
                <TextInput label="lineId(user input)" source="lineUser" className="inlineBlock" />
                <TextInput source="phoneNumber" className="inlineBlock" />
                <TextInput disabled source="coins" className="inlineBlock" />
                <BooleanInput source="disabled" className="inlineBlock" />
                <TextInput multiline source="address" />
                <AddButton />
                <DateField label="created" source="created" showTime style={{ marginLeft: '20px' }} />
                <DateField label="updated" source="updated" showTime style={{ marginLeft: '20px' }} />
            </FormTab>
            <FormTab label="Claims">
                <ReferenceManyField
                    reference="claims"
                    source="id"
                    target="member"
                    label=""
                >
                    <Datagrid>
                        <DateField showTime source="created" />
                        <TextField label="Product" source="product.name" />
                        <TextField label="User" source="member.userId" />
                        <TextField label="Email" source="member.email" />
                        <TextField label='Class' source="member.class" />
                        <TextField label="Price" source="product.price" />
                        <BooleanField source="fullfill"></BooleanField>
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Transactions">
                <ReferenceManyField
                    reference="transactions"
                    source="userId"
                    target="member"
                    label=""
                >
                    <Datagrid>
                        <DateField showTime source="created" />
                        <TextField label="Product" source="product.name" />
                        <TextField label="User" source="member" />
                        {/* <TextField label="User" source="member.userId" /> */}
                        {/* <TextField label='Class' source="member.class" /> */}
                        <TextField source="type" />
                        <TextField label="Changes" source="changes" />
                        <TextField label="Price" source="product.price" />
                        <TextField label="Balance" source="balanceAfter" />
                        <TextField source="reason" />
                        <TextField label="Operator" source="user.email" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
);