import * as React from "react";
import { SimpleForm, Edit, ImageInput, ImageField, TextInput, BooleanInput, SelectInput, Toolbar, SaveButton } from 'react-admin';

export const ProductEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput disabled source="group" style={{ display: 'inline-block', float: 'left', marginLeft: '20px' }} />
            <TextInput source="name" />
            <TextInput multiline source="tagline" style={{ display: 'inline-block', float: 'left', marginLeft: '20px' }} />
            <TextInput source="price" />
            <BooleanInput source="disabled" />
            <TextInput multiline source="details" style={{ marginLeft: '20px', width: "50%" }} />
            <ImageInput source="thumbnail" accept="image/*">
                <ImageField source="src" />
            </ImageInput>
            <SelectInput source="type" isRequired className="inlineBlock" choices={[
                { id: 'address', name: 'Address' },
                { id: 'email', name: 'Email' }
            ]} />
        </SimpleForm>
    </Edit>
);
const CustomToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
)