import * as React from "react";
import { Create, TextInput, BooleanInput, ImageInput, ImageField, SimpleForm, SelectInput, required, NumberInput } from 'react-admin';
// import { useWatch } from 'react-hook-form'

export const ProductCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput source="group" validate={required()} choices={[
                { id: 'food & cafe', name: 'Food & Cafe' },
                { id: 'others', name: 'Others' },
                { id: 'entertainment', name: 'Entertainment' },
                { id: 'gadget', name: 'Gadget' },
                { id: 'sports', name: 'Sports' }
            ]} />
            <TextInput source="name" validate={required()}/>
            <TextInput multiline source="tagline" validate={required()}/>
            <NumberInput source="price" validate={required()}/>
            <TextInput multiline source="details" validate={required()}/>
            <SelectInput source="type" validate={required()} choices={[
                { id: 'address', name: 'Address' },
                { id: 'email', name: 'Email' }
            ]} />
            <ImageInput source="thumbnail" accept="image/*" validate={required()}>
                <ImageField source="src" />
            </ImageInput>
            <BooleanInput source="disabled" initialValue={true}/>
        </SimpleForm>
    </Create>
);