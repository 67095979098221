import * as React from "react";
import {
    Create, TextInput, SelectInput, SimpleForm, required
} from 'react-admin';

export const LookupsCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="userId" validate={required()} style={{ display: 'inline-block', float: 'left', marginLeft: '20px' }} />
            <TextInput source="firstName" validate={required()} />
            <TextInput source="lastName" validate={required()} style={{ display: 'inline-block', float: 'left', marginLeft: '20px' }} />
            <SelectInput source="class" validate={required()} choices={[
                { id: 'Member', name: 'Member' },
                { id: 'Professional', name: 'Professional' },
                { id: 'Master', name: 'Master' },
                { id: 'Grandmaster', name: 'Grandmaster' },
            ]} />
        </SimpleForm>
    </Create>
);