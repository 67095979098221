import * as React from "react";
import {
    Edit, TextInput, SelectInput, SimpleForm, DateField
} from 'react-admin';

export const LookupsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm style={{ flexDirection: 'unset', flexWrap: 'wrap' }}>
            <TextInput source="userId" disabled className="inlineBlock" />
            <TextInput source="firstName" className="inlineBlock" />
            <TextInput source="lastName" className="inlineBlock" />
            <TextInput source="coins" disabled className="inlineBlock" />
            <SelectInput source="class" className="inlineBlock" choices={[
                { id: 'Member', name: 'Member' },
                { id: 'Professional', name: 'Professional' },
                { id: 'Master', name: 'Master' },
                { id: 'Grandmaster', name: 'Grandmaster' },
            ]} />
            <DateField source="updated" showTime />
        </SimpleForm>
    </Edit>
);