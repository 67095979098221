import * as React from "react";
import { Admin, Resource, Layout, AppBar, TitlePortal, usePermissions } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CategoryIcon from '@mui/icons-material/Category';
import SearchIcon from '@mui/icons-material/Search';
import authProvider from './provider/auth';
import { MemberList } from './component/Member/List'
import { MemberEdit } from "./component/Member/Edit";
import { TransactionList } from "./component/Transaction/List";
import { ClaimList } from "./component/Claim/List";
import { ClaimEdit } from "./component/Claim/Edit";
import { ProductEdit } from "./component/Products/Edit";
import { ProductsList } from "./component/Products/List";
import { ActivityList } from "./component/Activity/List";
import { ActivityEdit } from "./component/Activity/Edit";
import { ActivityCreate } from "./component/Activity/Create";
import { LookupsList } from "./component/Lookups/List";
import { LookupsCreate } from "./component/Lookups/Create";
import { LookupsEdit } from "./component/Lookups/Edit";
import { ProductCreate } from "./component/Products/Create";
import { customProvider } from "./provider/dataProvider";

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />

const CustomAppBar = (props) => {
  // const { data: identity } = useGetIdentity()
  const { permissions } = usePermissions()
  console.log(permissions)
  return <AppBar {...props}>
    <TitlePortal />
    <div style={{textTransform:'capitalize'}}>{permissions}</div>
  </AppBar>
}


const App = () => (
  <Admin requireAuth layout={CustomLayout} initialState={{ admin: { ui: { sidebarOpen: false } } }} disableTelemetry authProvider={authProvider} dataProvider={customProvider}>
    {permissions => <>
      {permissions === 'admin' && <Resource name="members" icon={PersonIcon} list={MemberList} edit={MemberEdit} />}
      <Resource name="backendLookup" options={{ label: 'Lookups' }} icon={SearchIcon} list={LookupsList} edit={LookupsEdit} create={LookupsCreate} />
      <Resource name="transactions" icon={ReceiptIcon} list={TransactionList} />
      <Resource name="claims" icon={ShoppingCartIcon} list={ClaimList} edit={ClaimEdit} />
      <Resource name="products" icon={CategoryIcon} list={ProductsList} edit={ProductEdit} create={ProductCreate} />
      <Resource name="activity" icon={MonetizationOnIcon} list={ActivityList} edit={ActivityEdit} create={ActivityCreate} />
      <Resource name="raffle" />
    </>}
  </Admin>
);

export default App;