// import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import axios from 'axios'
import Swal from 'sweetalert2'


const ImportButton = ({ record, ...rest }) => {
    const credential = JSON.parse(localStorage.getItem('credential'));
    async function importFile() {
        const { value: file } = await Swal.fire({ title: 'Import Lookup', input: 'file' });
        if (file) {
            try {
                const fd = new FormData()
                fd.append('file', file);
                Swal.fire({
                    title: 'Processing', didOpen: () => {
                        Swal.showLoading()
                    }
                })
                const response = await axios.post(process.env.REACT_APP_API + "/backendLookup/importFile", fd,
                    {
                        headers: {
                            Authorization: 'Bearer ' + credential?.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                console.log('response', response)
                await Swal.fire(
                    'Import File Success',
                    '',
                    'success'
                )
            } catch (e) {
                console.log('EEEE', e)
                await Swal.fire({ title: 'Error', icon: 'error', text: e.response.data.error });
                console.log('E', e.response.data.error)
            }

        }

    }
    return <Button variant="contained" label="Import Lookup" color="primary" style={rest.style} onClick={importFile}>Import Lookup</Button>
}

export default ImportButton;