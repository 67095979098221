import { fetchUtils } from "react-admin";
import simpleRestProvider from 'ra-data-simple-rest';
const httpClient = (url, options = {}) => {
    const credential = JSON.parse(localStorage.getItem('credential'));
    options.headers = new Headers({ Authorization: `Bearer ${credential?.token}` });
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(process.env.REACT_APP_API, httpClient);

export const customProvider = {
    ...dataProvider,
    create: (resource, params) => {
        if (resource === 'activity') {
            let formData = new FormData();
            formData.append('name', params.data.name)
            formData.append('type', params.data.type)
            formData.append('details', params.data.details)
            formData.append('thumbnail', params.data.thumbnail.rawFile)
            formData.append('header', params.data.header.rawFile)
            formData.append('raffle', params.data.raffle)
            formData.append('price', params.data.price)
            formData.append('open', params.data.open)
            return httpClient(`${process.env.REACT_APP_API}/${resource}`, {
                method: 'POST',
                body: formData
            }).then(({ json }) => ({
                data: { ...params.data, id: json._id }
            }))
        }
        else if (resource === 'products') {
            let formData = new FormData();
            formData.append('group', params.data.group)
            formData.append('name', params.data.name)
            formData.append('tagline', params.data.tagline)
            formData.append('price', params.data.price)
            formData.append('details', params.data.details)
            formData.append('type', params.data.type)
            formData.append('disabled', params.data.disabled)
            formData.append('thumbnail', params.data.thumbnail.rawFile)
            return httpClient(`${process.env.REACT_APP_API}/${resource}`, {
                method: 'POST',
                body: formData
            }).then(({ json }) => ({
                data: { ...params.data, id: json._id }
            }))
        } else {
            return dataProvider.create(resource, params);
        }
    },
    update: (resource, params) => {
        if (resource === 'activity') {
            let formData = new FormData();
            if (params.data.name) {
                formData.append('name', params.data.name)
            }
            if (params.data.type) {
                formData.append('type', params.data.type)
            }
            if (params.data.details) {
                formData.append('details', params.data.details)
            }
            if (params.data.thumbnail) {
                formData.append('thumbnail', params.data.thumbnail.rawFile)
            }
            if (params.data.header) {
                formData.append('header', params.data.header.rawFile)
            }
            if (params.data.price) {
                formData.append('price', params.data.price)
            }
            formData.append('hide', params.data.hide)
            formData.append('open', params.data.open)
            formData.append('raffle', params.data.raffle)
            return httpClient(`${process.env.REACT_APP_API}/${resource}/${params.id}`, {
                method: 'PUT',
                body: formData
            }).then(({ json }) => ({
                data: { ...params.data, id: json._id }
            }))
        } else if (resource === 'products') {
            let formData = new FormData();
            if (params.data.group) {
                formData.append('group', params.data.group)
            }
            if (params.data.name) {
                formData.append('name', params.data.name)
            }
            if (params.data.tagline) {
                formData.append('tagline', params.data.tagline)
            }
            if (params.data.price) {
                formData.append('price', params.data.price)
            }
            if (params.data.details) {
                formData.append('details', params.data.details)
            }
            if (params.data.type) {
                formData.append('type', params.data.type)
            }
            formData.append('disabled', params.data.disabled);
            if (params.data.thumbnail) {
                formData.append('thumbnail', params.data.thumbnail.rawFile)
            }
            return httpClient(`${process.env.REACT_APP_API}/${resource}/${params.id}`, {
                method: 'PUT',
                body: formData
            }).then(({ json }) => ({
                data: { ...params.data, id: json._id }
            }))
        } else {
            return dataProvider.update(resource, params);
        }
    }
}